import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Login from "./components/Login";
import {
   PATH_BIRTHDAYS,
   PATH_CLEVER_REACH,
   PATH_COUPON,
   PATH_DASHBOARD,
   PATH_LOGIN,
   PATH_OPEN_EMAIL_EDIT,
   PATH_OPEN_SUBSCRIPTION
} from "./lib/constance";
import Dashboard from "./components/Dashboard";
import Birthdays from "./components/Birthdays";
import DateAdapter from "@mui/lab/AdapterMoment";
import { LocalizationProvider } from "@mui/lab";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router";
import ContentWrapper from "./components/ContentWrapper";
import CleverReach from "./components/CleverReach";
import CustomerEmailSwitcher from "./components/open/CustomerEmailSwitcher";
import Coupons from "./components/Coupons";
import CustomerEmailEdit from "./components/open/CustomerEmailEdit";

function App() {
   const theme = createTheme();

   const navigate = useNavigate();

   return (
      <div className="App">
         <LocalizationProvider dateAdapter={DateAdapter}>
            <ThemeProvider theme={theme}>
               <CssBaseline />
               <Container component="main" maxWidth="xl">
                  <Routes>
                     <Route
                        path={PATH_OPEN_SUBSCRIPTION}
                        element={
                           <ContentWrapper
                              menu={false}
                              child={<CustomerEmailSwitcher />}
                           />
                        }
                     />
                     <Route
                        path={PATH_OPEN_EMAIL_EDIT}
                        element={
                           <ContentWrapper
                              menu={false}
                              child={<CustomerEmailEdit />}
                           />
                        }
                     />

                     <Route
                        path={PATH_LOGIN}
                        element={
                           <ContentWrapper menu={false} child={<Login />} />
                        }
                     />
                     <Route
                        path={PATH_DASHBOARD}
                        element={
                           <ContentWrapper menu={true} child={<Dashboard />} />
                        }
                     />
                     <Route
                        path={PATH_BIRTHDAYS}
                        element={
                           <ContentWrapper menu={true} child={<Birthdays />} />
                        }
                     />
                     <Route
                        path={PATH_COUPON}
                        element={
                           <ContentWrapper menu={true} child={<Coupons />} />
                        }
                     />

                     <Route
                        path="*"
                        element={
                           <React.Fragment>
                              <Alert severity="error">404</Alert>
                           </React.Fragment>
                        }
                     />
                  </Routes>
               </Container>
            </ThemeProvider>
         </LocalizationProvider>
      </div>
   );
}

export default App;
