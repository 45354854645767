export const Helper: any = {
   validatorEmail: function (value: string = "") {
      if (value === undefined || value === "") {
         return false;
      }
      var x = /\S+@\S+\.\S+/;
      return x.test(value);
   },
   validatorPassword: function (value: string | undefined) {
      // var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]){8,}/;
      // return re.test(value);
      return value !== undefined && value !== "" && value.length > 6;
   },
   validatorIsEmpty: function (value: string | undefined) {
      return value !== undefined && value !== "";
   },
   validatorMin: function (value: string | undefined, anz: number = 3) {
      return value !== undefined && value !== "" && value.length >= anz;
   },
   validatorEmptyIsValid: function (value: string | undefined) {
      return true;
   },
   nullAsEmpty(val: any) {
      if (val === undefined || val === null) {
         return "";
      }
      return val;
   }
};
