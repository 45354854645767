import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Rest } from "../lib/rest";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import {
   API_BIRTHDAYCUSTOMERS,
   API_COUPON, API_COUPON_USED,
   API_CR_GROUPS,
   API_CR_GROUPS_RECEIVERS,
   API_CR_WEBHOOKS,
   PATH_DASHBOARD
} from "../lib/constance";
import { useNavigate } from "react-router";
import { setJwtTokenFromStorage } from "../lib/Storeage";
import {
   Badge,
   Card,
   CardActions,
   CardContent,
   CardHeader,
   Grid
} from "@mui/material";
import Geburtstage from "./dashboardcards/Geburtstage";
import moment from "moment";
import { getUrl } from "../lib/utils";
import { Coupon, CRGroup, CRReceiver, Customer } from "../lib/Types";
import { toCurrency } from "./Utils";

export default function Coupons() {
   useEffect(() => {}, []);

   const [coupon, setCoupon] = useState<Coupon | null>(null);
   const [code, setCode] = useState<string>("");
   const template ="XXXX-XXXX-XXXX-XXXX-XX";
   const textInput = useRef<HTMLHeadingElement>(null);

   const load = (c:string)=>{
      Rest.GET(getUrl(API_COUPON, [c]), (res) => {
         setCoupon(res);
      },()=>{
         setCoupon(null);
      });
   }
   return (
      <React.Fragment>
         <Container component="main" maxWidth="xl">
            <Grid
               container
               spacing={1}
               justifyContent={"center"}
               alignContent={"center"}>
               <Grid item xs={12}>
                  <TextField
                      inputRef={textInput}
                     label="Code"
                     color="secondary"
                     focused
                     onChange={(e) => {
                        setCode(e.target.value);
                        if(e.target.value.length >= template.length) {
                           load(e.target.value);
                           e.target.select();
                        }
                     }}
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12}>
                  <Button
                     fullWidth
                     disabled={code.length != template.length}
                     size={"large"}
                     variant={"contained"}
                     onClick={(e) => {
                           load(code);
                     }}>
                     Check Code
                  </Button>
               </Grid>
            </Grid>

            <Grid container spacing={2} style={{ paddingTop: "5%" }}>
               <Grid item xs={12}>
                  {coupon !== null ? (
                     <Card raised={true}>
                        <CardContent>
                           {coupon.used ? (
                               <DoNotDisturbAltIcon fontSize="large" color="error" />
                           ) : (
                               <CheckCircleOutlineIcon fontSize="large" color="success" />
                           )}
                           <Typography
                              gutterBottom
                              variant="h5"
                              component="div">
                                  {coupon.code}
                           </Typography>

                           <Typography variant="h5" component="div">
                              {coupon.fixedValue
                                 ? toCurrency(coupon.value)
                                 : coupon.value + " %"}
                           </Typography>

                           {coupon.used ? (
                               <Typography
                                   gutterBottom
                                   variant="h5"
                                   component="div">
                                  Verwendet am : {moment(coupon.useDate).format('DD.MM.YYYY hh:mm')}
                               </Typography>
                           ) : (
                               <Button
                                   size={"large"}
                                   variant={"contained"}
                                   color={"success"}
                                   onClick={(e) => {
                                      Rest.GET(getUrl(API_COUPON_USED, [""+coupon.id]), (res) => {
                                         setCoupon(res);
                                      });
                                      if(textInput!==null && textInput.current!==null) {
                                         textInput.current.focus();
                                      }
                                   }}>
                                  Jetzt Verwenden
                               </Button>
                           )}

                           {coupon.customer !== null ? (
                              <React.Fragment>
                                 <hr />
                                 <Typography variant="h5" component="div">
                                    {coupon.customer.anrede}{" "}
                                    {coupon.customer.vorname}{" "}
                                    {coupon.customer.name} (
                                    {coupon.customer.hisNr})
                                 </Typography>

                                 <Typography variant="body2" component="div">
                                    Alter {coupon.customer.yearsOld} -{" "}
                                    {coupon.customer.plz} {coupon.customer.ort},{" "}
                                    {coupon.customer.strasse}
                                 </Typography>
                              </React.Fragment>
                           ) : (
                              ""
                           )}
                        </CardContent>
                     </Card>
                  ) : (
                      <>{code} unbekannt</>
                  )}
               </Grid>
            </Grid>
         </Container>
      </React.Fragment>
   );
}
