import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Rest } from "../lib/rest";

import {PATH_COUPON, PATH_DASHBOARD} from "../lib/constance";
import { useNavigate } from "react-router";
import {setJwtTokenFromStorage, setRoleFromStorage, setUserFromStorage} from "../lib/Storeage";

export default function Login() {
   const navigate = useNavigate();

   const handleLogin = () => {
      Rest.POST_LOGIN(
         { username: email, password: password },
         () => {},
         (res) => {
            setJwtTokenFromStorage(res.jwtToken);
            setUserFromStorage(res.user.email);
            setRoleFromStorage(res.user.role);
            if(res.user.role != "Admin") {
              navigate(PATH_COUPON);
            } else {
              navigate(PATH_DASHBOARD);
            }

         }
      );
   };

   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");

   return (
      <React.Fragment>
         <Container component="main" maxWidth="xs">
            <Box
               sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
               }}>
               <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
               </Avatar>
               <Typography component="h1" variant="h5">
                  Sign in
               </Typography>
               <Box sx={{ mt: 1 }}>
                  <TextField
                     margin="normal"
                     required
                     fullWidth
                     value={email}
                     onChange={(e) => {
                        setEmail(e.target.value);
                     }}
                     id="email"
                     label="Email Address"
                     name="email"
                     autoComplete="email"
                     autoFocus
                  />
                  <TextField
                     margin="normal"
                     required
                     fullWidth
                     name="password"
                     value={password}
                     onChange={(e) => {
                        setPassword(e.target.value);
                     }}
                     label="Password"
                     type="password"
                     id="password"
                     autoComplete="current-password"
                  />
                  <Button
                     fullWidth
                     variant="contained"
                     sx={{ mt: 3, mb: 2 }}
                     onClick={handleLogin}>
                     Sign In
                  </Button>
               </Box>
            </Box>
         </Container>
      </React.Fragment>
   );
}
