import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Card, CardActions, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import { API_BIRTHDAYCOUNT, PATH_BIRTHDAYS } from "../../lib/constance";
import { Rest } from "../../lib/rest";
import { useNavigate } from "react-router";

export default function Geburtstage() {
   const navigate = useNavigate();
   const [count, setCount] = useState(0);

   useEffect(() => {
      Rest.GET(API_BIRTHDAYCOUNT, (res) => {
         setCount(res.count);
      });
   });

   return (
      <React.Fragment>
         <Card>
            <CardContent>
               <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom>
                  Heute hat Geburstag
               </Typography>
               <Typography variant="h5" component="div">
                  {count}
               </Typography>
            </CardContent>
         </Card>
      </React.Fragment>
   );
}
