import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Rest } from "../lib/rest";

import { PATH_DASHBOARD } from "../lib/constance";
import { useNavigate } from "react-router";
import { setJwtTokenFromStorage } from "../lib/Storeage";
import { Card, CardActions, CardContent, Grid } from "@mui/material";
import Geburtstage from "./dashboardcards/Geburtstage";
import Jobs from "./dashboardcards/Jobs";

export default function Dashboard() {
   return (
      <React.Fragment>
         <Container component="main" maxWidth="xs">
            <Grid container spacing={2}>
               <Grid item xs={6}>
                  <Geburtstage />
               </Grid>
               <Grid item xs={6}>
                  <Jobs />
               </Grid>
            </Grid>
         </Container>
      </React.Fragment>
   );
}
