import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Grid } from "@mui/material";
import { API_JOBS_BASE } from "../../lib/constance";
import { Rest } from "../../lib/rest";
import { useNavigate } from "react-router";

export default function Geburtstage() {
   const navigate = useNavigate();
   const [count, setCount] = useState(0);

   useEffect(() => {});

   return (
      <React.Fragment>
         <Card>
            <CardContent>
               <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom>
                  Job Trigger
               </Typography>
               <Grid container spacing={2}>
                  <Grid item xs={12}>
                     <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                           Rest.GET(API_JOBS_BASE + "welcome", (res) => {
                              console.log("sendBirthdayPDFListJob", res);
                           });
                        }}>
                        welcome
                     </Button>
                  </Grid>
                  <Grid item xs={12}>
                     <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                           Rest.GET(
                              API_JOBS_BASE + "sendBirthdayPDFListJob",
                              (res) => {
                                 console.log("sendBirthdayPDFListJob", res);
                              }
                           );
                        }}>
                        send Birthday PostPDF to backoffice
                     </Button>
                  </Grid>
                  <Grid item xs={12}>
                     <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                           Rest.GET(
                              API_JOBS_BASE + "updateBirthdayList",
                              (res) => {
                                 console.log("updateBirthdayList", res);
                              }
                           );
                        }}>
                        send Birthdays emails to customer
                     </Button>
                  </Grid>
                  <Grid item xs={12}>
                     <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                           Rest.GET(
                              API_JOBS_BASE + "importHISCSVJob",
                              (res) => {
                                 console.log("importHISCSVJob", res);
                              }
                           );
                        }}>
                        import HIS CSV
                     </Button>
                  </Grid>
               </Grid>
            </CardContent>
         </Card>
      </React.Fragment>
   );
}
