export const PATH_LOGIN = "/Login";
export const PATH_DASHBOARD = "/dashboard";
export const PATH_COUPON = "/coupons";
export const PATH_BIRTHDAYS = "/birthdays";
export const PATH_CLEVER_REACH = "/cleverReach";

export const PATH_OPEN_SUBSCRIPTION = "/subscription/:token";
export const PATH_OPEN_EMAIL_EDIT = "/email/:token";

if (process.env.NODE_ENV !== "development") {
   console.log = function () {};
}

export const BASE_URL = process.env.REACT_APP_BASEURL;

export const API_AUTH = "/v1/auth/login";
export const API_LOGOUT = "/v1/auth/logout";
export const API_BIRTHDAYCOUNT = "/v1/customer/birthdayCount";
export const API_BIRTHDAYCUSTOMERS = "/v1/customer/birthdayCustomers/{0}";

export const API_CR_GROUPS = "/v1/CleverReach/groups";
export const API_CR_GROUPS_RECEIVERS = "/v1/CleverReach/receivers";
export const API_CR_WEBHOOKS = "/v1/CleverReach/webhooks";

export const API_COUPON = "/v1/coupon/{0}";
export const API_COUPON_USED = "/v1/coupon/used/{0}";

export const API_OPEN_CUSTOMER = "/v1/open/customer/{0}";
export const API_OPEN_CUSTOMER_EMAIL = "/v1/open/customer/{0}/email";
export const API_OPEN_CUSTOMER_SET_SUBSCRIPTION =
   "/v1/open/customer/{0}/subscription";
export const API_OPEN_CUSTOMER_SET_SUBSCRIPTION_EMAIL =
   "/v1/open/customer/{0}/email";

export const API_JOBS_BASE = "/v1/jobs/";
