import * as React from "react";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Rest } from "../lib/rest";

import { API_BIRTHDAYCUSTOMERS, API_JOBS_BASE } from "../lib/constance";
import { useNavigate } from "react-router";
import { getUrl } from "../lib/utils";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import TextField from "@mui/material/TextField";
import {
   Grid,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TablePagination,
   TableRow
} from "@mui/material";
import { Customer } from "../lib/Types";
import moment from "moment";
import Button from "@mui/material/Button";

export default function Birthdays() {
   const columns = ["Quelle", "Name", "Alter", "eMail", "PLZ"];
   const [date, setDate] = React.useState<Date>(new Date());
   const navigate = useNavigate();
   const [customers, setCustomers] = useState<Customer[]>([]);
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);

   useEffect(() => {
      getCustomers(date);
   }, []);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(0);
   };

   const getCustomers = (d: Date) => {
      let a = moment(d);
      let b = moment().startOf("day");
      let offset = a.diff(b, "days");
      console.log(
         "OFFSET",
         offset,
         getUrl(API_BIRTHDAYCUSTOMERS, ["" + offset])
      );
      Rest.GET(getUrl(API_BIRTHDAYCUSTOMERS, ["" + offset]), (res) => {
         setCustomers(res);
      });
   };
   return (
      <React.Fragment>
         <Grid container justifyContent={"center"} spacing={3}>
            <Grid item>
               <MobileDatePicker
                  label="Datum"
                  inputFormat="dd.MM.yyyy"
                  value={date}
                  onChange={(e: Date | null) => {
                     if (e !== null) {
                        let d = new Date(e);
                        setDate(d);
                        getCustomers(d);
                     }
                  }}
                  renderInput={(params: any) => <TextField {...params} />}
               />
            </Grid>
            <Grid item>
               <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                     Rest.GET(
                        API_JOBS_BASE + "sendBirthdayPDFListJob",
                        (res) => {
                           console.log("sendBirthdayPDFListJob", res);
                        }
                     );
                  }}>
                  PDF erstellen ({date.toLocaleDateString()})
               </Button>
            </Grid>
         </Grid>
         <Grid container>
            <Grid item xs={12}>
               <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell key={column} style={{ minWidth: 50 }}>
                                 {column}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>

                     <TableBody>
                        {customers
                           .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                           )
                           .map((row: Customer) => {
                              return (
                                 <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}>
                                    <TableCell>
                                       {row.source}-{row.hisNr}
                                    </TableCell>
                                    <TableCell>
                                       {row.anrede} {row.vorname} {row.name}
                                    </TableCell>
                                    <TableCell>{row.yearsOld} Jahre</TableCell>
                                    <TableCell>
                                       <a
                                          target="_blank"
                                          href={
                                             "/subscription/" + row.customerHash
                                          }>
                                          {row.email}
                                       </a>
                                    </TableCell>
                                    <TableCell>{row.plz}</TableCell>
                                 </TableRow>
                              );
                           })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  component="div"
                  count={customers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Grid>
         </Grid>
      </React.Fragment>
   );
}
