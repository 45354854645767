import * as React from 'react';
import {Component, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Rest} from "../lib/rest";

import {PATH_DASHBOARD} from "../lib/constance";
import {useNavigate} from "react-router";
import {setJwtTokenFromStorage} from "../lib/Storeage";
import {Card, CardActions, CardContent, Grid} from "@mui/material";
import Geburtstage from "./dashboardcards/Geburtstage";
import Menu from "./Menu";

interface comps {
     child: any;
     menu:boolean;
}

export default function ContentWrapper(props:comps) {

  if(props.menu){
  return (
      <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Menu/>
            </Grid>
            <Grid item xs={12}>
              {props.child}
            </Grid>
          </Grid>
      </React.Fragment>
  );
  } else {
    return (<React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {props.child}
        </Grid>
      </Grid>
    </React.Fragment>);
  }
}