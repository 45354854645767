import { getJwtTokenFromStorage } from "./Storeage";
import { API_AUTH, BASE_URL, PATH_LOGIN } from "./constance";

export const getHttpHeader = (noAUTH = false) => {
   return {
      "Content-Type": "application/json",
      ...(!noAUTH && { Authorization: "Bearer " + getJwtTokenFromStorage() }),
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0"
   };
};

export const getUnauthorizedHttpHeader = () => {
   return {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0"
   };
};

const handleUnauthenticatedOrUnauthorized = (response) => {
   if (response.status === 401) {
      window.location.href = PATH_LOGIN;
      return false;
   }
   return true;
};

export const Rest = {
   /**
    * Rest API GET Request
    *
    * @param url fetch URL
    * @param onSuccess callback function
    * @param onError callback function
    * @param doNotParse true, if the response shall not be parsed as json
    * @param onFinally callback function
    */
   GET: (
      url,
      onSuccess = (result) => {},
      onError = (error) => {},
      doNotParse = false,
      onFinally = () => {}
   ) => {
      return fetch(BASE_URL + url, {
         method: "GET",
         headers: getHttpHeader()
      })
         .then((response) => {
            if (!handleUnauthenticatedOrUnauthorized(response)) {
               return null;
            }
            if (doNotParse) {
               return response.text();
            }
            try {
               return response.json();
            } catch (e) {
               return response.text();
            }
         })
         .then((result) => {
            console.log("Rest", url, result);
            if (result !== null) {
               onSuccess(result);
            }
            return result;
         })
         .catch((err) => {
            console.error("Rest", url, err);
            onError(err);
         })
         .finally(() => {
            onFinally();
         });
   },

   _GET: (
      url,
      onSuccess = (result) => {},
      onError = (error) => {},
      doNotParse = false,
      onFinally = () => {}
   ) => {
      return fetch(BASE_URL + url, {
         method: "GET",
         headers: getHttpHeader(true)
      })
         .then((response) => {
            if (doNotParse) {
               return response.text();
            }
            try {
               return response.json();
            } catch (e) {
               return response.text();
            }
         })
         .then((result) => {
            console.log("Rest", url, result);
            if (result !== null) {
               onSuccess(result);
            }
            return result;
         })
         .catch((err) => {
            console.error("Rest", url, err);
            onError(err);
         })
         .finally(() => {
            onFinally();
         });
   },

   /**
    * Rest API GET Request with await
    *
    * @param url fetch URL
    * @param onSuccess callback function
    * @param onError callback function
    * @param doNotParse true, if the response shall not be parsed as json
    * @param onFinally callback function
    */
   GET_AWAIT: async (
      url,
      onSuccess = (result) => {},
      onError = (error) => {},
      doNotParse = false,
      onFinally = () => {}
   ) => {
      await Rest.GET(url, onSuccess, onError, doNotParse, onFinally);
   },

   /**
    * Rest API POST Request with await
    *
    * @param url fetch Url
    * @param data Object to POST
    * @param onSuccess callback function
    * @param onError callback function
    * @param doNotParse true, if the response shall not be parsed as json
    * @param onFinally callback function
    * @param doNotStringifyBodyData true, if the body data shall not be stringified as json
    * @param noContentType true, if no content type shall be presented
    */
   POST_AWAIT: async (
      url,
      data = {},
      onSuccess = (result) => {},
      onError = (error) => {},
      doNotParse = false,
      onFinally = () => {},
      doNotStringifyBodyData = false,
      noContentType = false
   ) => {
      await Rest.POST(
         url,
         data,
         onSuccess,
         onError,
         doNotParse,
         onFinally,
         doNotStringifyBodyData,
         noContentType
      );
   },

   /**
    * Rest API POST Request
    *
    * @param url fetch Url
    * @param data Object to POST
    * @param onSuccess callback function
    * @param onError callback function
    * @param doNotParse true, if the response shall not be parsed as json
    * @param onFinally callback function
    * @param doNotStringifyBodyData true, if the body data shall not be stringified as json
    * @param noContentType true, if no content type shall be presented
    */
   _POST: (
      url,
      data = {},
      onSuccess = (result) => {},
      onError = (error) => {},
      doNotParse = false,
      onFinally = () => {},
      doNotStringifyBodyData = false
   ) => {
      return fetch(BASE_URL + url, {
         method: "POST",
         headers: getHttpHeader(true),
         body: doNotStringifyBodyData ? data : JSON.stringify(data)
      })
         .then((response) => {
            if (!handleUnauthenticatedOrUnauthorized(response)) {
               return null;
            }
            if (doNotParse) {
               return response.text();
            }
            try {
               return response.json();
            } catch (e) {
               return response.text();
            }
         })
         .then((result) => {
            console.log("Rest", url, result);
            if (result !== null) {
               onSuccess(result);
            }
         })
         .catch((err) => {
            console.error("Rest", url, err);
            onError(err);
         })
         .finally(() => {
            onFinally();
         });
   },

   POST: (
      url,
      data = {},
      onSuccess = (result) => {},
      onError = (error) => {},
      doNotParse = false,
      onFinally = () => {},
      doNotStringifyBodyData = false
   ) => {
      return fetch(BASE_URL + url, {
         method: "POST",
         headers: getHttpHeader(true),
         body: doNotStringifyBodyData ? data : JSON.stringify(data)
      })
         .then((response) => {
            if (!handleUnauthenticatedOrUnauthorized(response)) {
               return null;
            }
            if (doNotParse) {
               return response.text();
            }
            try {
               return response.json();
            } catch (e) {
               return response.text();
            }
         })
         .then((result) => {
            console.log("Rest", url, result);
            if (result !== null) {
               onSuccess(result);
            }
         })
         .catch((err) => {
            console.error("Rest", url, err);
            onError(err);
         })
         .finally(() => {
            onFinally();
         });
   },

   /**
    * Rest API POST Request to login.
    *
    * @param data Object to POST
    * @param onStatusNot200 callback function. Called, if return status is not 200.
    * @param onSuccess callback function
    * @param onError callback function
    */
   POST_LOGIN: (
      data,
      onStatusNot200,
      onSuccess = (result) => {},
      onError = (error) => {}
   ) => {
      fetch(BASE_URL + API_AUTH, {
         method: "POST",
         body: JSON.stringify(data),
         headers: getHttpHeader(true)
      })
         .then((response) => {
            if (response.status === 200) {
               return response.json();
            }
            onStatusNot200();
            return null;
         })
         .then(onSuccess)
         .catch(onError);
   },

   /**
    * Rest PUT POST Request
    *
    * @param url  fetch Url
    * @param data Object to POST
    * @param onSuccess callback function
    * @param onError callback function
    * @param doNotParse true, if the response shall not be parsed as json
    * @param onFinally callback function
    */
   PUT: (
      url,
      data = {},
      onSuccess = (result) => {},
      onError = (error) => {},
      doNotParse = false,
      onFinally = () => {}
   ) => {
      fetch(BASE_URL + url, {
         method: "PUT",
         headers: getHttpHeader(),
         body: JSON.stringify(data)
      })
         .then((response) => {
            if (!handleUnauthenticatedOrUnauthorized(response)) {
               return null;
            }
            if (doNotParse) {
               return response.text();
            }
            try {
               return response.json();
            } catch (e) {
               return response.text();
            }
         })
         .then((result) => {
            console.log("Rest", url, result);
            if (result !== null) {
               onSuccess(result);
            }
         })
         .catch((err) => {
            console.error("Rest", url, err);
            onError(err);
         })
         .finally(() => {
            onFinally();
         });
   },

   /**
    * Rest API DELETE Request
    *
    * @param url fetch URL
    * @param onSuccess callback function
    * @param onError callback function
    * @param doNotParse true, if the response shall not be parsed as json
    */
   DELETE: (
      url,
      onSuccess = (result) => {},
      onError = (error) => {},
      doNotParse = false
   ) => {
      fetch(BASE_URL + url, {
         method: "DELETE",
         headers: getHttpHeader()
      })
         .then((response) => {
            if (!handleUnauthenticatedOrUnauthorized(response)) {
               return null;
            }
            if (doNotParse) {
               return response.text();
            }
            try {
               return response.json();
            } catch (e) {
               return response.text();
            }
         })
         .then((result) => {
            console.log("Rest", url, result);
            if (result !== null) {
               onSuccess(result);
            }
         })
         .catch((err) => {
            console.error("Rest", url, err);
            onError(err);
         });
   }
};
