
const KEY_TOKEN :string = "XXSSERRR%$$§§$";
const KEY_USER :string = "XXSSERRR%$0§§$";
const KEY_ROLE :string = "XXSSERRR%O$§§$";


export function getJwtTokenFromStorage() : string {
  let t =  sessionStorage.getItem(KEY_TOKEN);
  return t===null?"invalic":t;
};

export function setJwtTokenFromStorage(token: string) {
  sessionStorage.setItem(KEY_TOKEN,token);
};

export function getUserFromStorage() : string {
  let t =  sessionStorage.getItem(KEY_USER);
  return t===null?"invalic":t;
};

export function setUserFromStorage(token: string) {
  sessionStorage.setItem(KEY_USER,token);
};


export function getRoleFromStorage() : string {
  let t =  sessionStorage.getItem(KEY_ROLE);
  return t===null?"invalic":t;
};

export function setRoleFromStorage(token: string) {
  sessionStorage.setItem(KEY_ROLE,token);
};

