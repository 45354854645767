import * as React from "react";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Rest } from "../../lib/rest";

import {
   API_CR_GROUPS_RECEIVERS,
   API_OPEN_CUSTOMER,
   API_OPEN_CUSTOMER_SET_SUBSCRIPTION
} from "../../lib/constance";
import { useParams } from "react-router";
import {
   Card,
   CardActions,
   CardContent,
   FormControlLabel,
   FormGroup,
   Grid,
   Switch
} from "@mui/material";
import { getUrl } from "../../lib/utils";
import { Customer, SubscriptionsDTO } from "../../lib/Types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

interface IProps {}

export default function CustomerEmailSwitcher(props: IProps) {
   const { token } = useParams();
   const [subscriptions, setSubscriptions] = useState<SubscriptionsDTO | null>(
      null
   );
   const [success, setSuccess] = useState<boolean>(false);

   useEffect(() => {
      getCustomer();
   }, []);

   const getCustomer = () => {
      Rest._GET(getUrl(API_OPEN_CUSTOMER, ["" + token]), (res) => {
         setSubscriptions(res);
      });
   };

   if (subscriptions === null) {
      return <React.Fragment>loading</React.Fragment>;
   }

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSubscriptions({
         ...subscriptions,
         [event.target.name]: event.target.checked
      });
      console.log(subscriptions);
   };

   return (
      <React.Fragment>
         <Container component="main" maxWidth="md">
            <Box
               sx={{
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
               }}>
               <Box justifyContent={"center"}>
                  <img src="/schuhbaar.png" />
               </Box>
               {success ? (
                  <React.Fragment>
                     <Alert severity="success">
                        <Typography variant={"body2"}>
                           Einstellungen erfolgreich aktualisiert.
                        </Typography>
                     </Alert>
                     <Typography variant={"h5"} style={{ paddingTop: "20px" }}>
                        <a href="https://www.schuhbaar.de">www.schuhbaar.de</a>
                     </Typography>
                  </React.Fragment>
               ) : (
                  <Card>
                     <CardContent>
                        <FormGroup>
                           <FormControlLabel
                              control={
                                 <Switch
                                    checked={subscriptions.newsletter}
                                    onChange={handleChange}
                                 />
                              }
                              name="newsletter"
                              label="Newsletter"
                           />

                           <FormControlLabel
                              control={
                                 <Switch
                                    checked={subscriptions.newsletterBirthday}
                                    onChange={handleChange}
                                 />
                              }
                              name="newsletterBirthday"
                              label="Newsletter zum Geburtstag"
                           />
                           <FormControlLabel
                              control={
                                 <Switch
                                    checked={subscriptions.newsletterEvents}
                                    onChange={handleChange}
                                 />
                              }
                              label="Newsletter für besondere Events"
                              name="newsletterEvents"
                           />
                           {!subscriptions.postBirthday ? (
                              <FormControlLabel
                                 control={
                                    <Switch
                                       checked={subscriptions.postBirthday}
                                       onChange={handleChange}
                                    />
                                 }
                                 label="Post zum Geburtstag"
                                 name="postBirthday"
                              />
                           ) : null}

                           {!subscriptions.postEvents ? (
                              <FormControlLabel
                                 control={
                                    <Switch
                                       checked={subscriptions.postEvents}
                                       onChange={handleChange}
                                    />
                                 }
                                 name="postEvents"
                                 label="Post für besondere Events"
                              />
                           ) : null}
                        </FormGroup>
                     </CardContent>
                     <CardActions style={{ justifyContent: "center" }}>
                        <Button
                           size="medium"
                           variant={"contained"}
                           onClick={() => {
                              Rest._POST(
                                 getUrl(API_OPEN_CUSTOMER_SET_SUBSCRIPTION, [
                                    subscriptions.customerHash
                                 ]),
                                 subscriptions,
                                 (res) => {
                                    setSuccess(true);
                                 }
                              );
                           }}>
                           Speichern
                        </Button>
                     </CardActions>
                  </Card>
               )}
            </Box>
         </Container>
      </React.Fragment>
   );
}
