import * as React from "react";
import Box from "@mui/material/Box";
import { Rest } from "../lib/rest";

import {
   API_LOGOUT,
   PATH_BIRTHDAYS,
   PATH_CLEVER_REACH,
   PATH_COUPON,
   PATH_DASHBOARD,
   PATH_LOGIN
} from "../lib/constance";
import { useNavigate } from "react-router";
import {getRoleFromStorage, setJwtTokenFromStorage} from "../lib/Storeage";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CakeIcon from "@mui/icons-material/Cake";
import LogoutIcon from "@mui/icons-material/Logout";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

export default function Menu() {
   const navigate = useNavigate();
   const [value, setValue] = React.useState(0);

   return (
      <React.Fragment>

         <Box sx={{ width: "100%" }}>
            <BottomNavigation
               showLabels
               value={value}
               onChange={(event, newValue) => {
                  setValue(newValue);
               }}>
               {getRoleFromStorage()=="Admin"?
               <React.Fragment>
                  <BottomNavigationAction
                  label="Dashboard"
                  icon={<DashboardIcon />}
                  onClick={() => navigate(PATH_DASHBOARD)}
               />

               <BottomNavigationAction
                  label="Geburtstage"
                  icon={<CakeIcon />}
                  onClick={() => navigate(PATH_BIRTHDAYS)}
               />
               </React.Fragment>
                  :null}
               <BottomNavigationAction
                  label="Gutscheine"
                  icon={<MonetizationOnIcon />}
                  onClick={() => navigate(PATH_COUPON)}
               />

               {/*
                <BottomNavigationAction label="CleverReach" icon={<ApiIcon/>}
                                        onClick={() => navigate(PATH_CLEVER_REACH)}/>
              */}
               <BottomNavigationAction
                  label="Logout"
                  icon={<LogoutIcon />}
                  onClick={() => {
                     Rest.GET(API_LOGOUT, () => {
                        setJwtTokenFromStorage("");
                        navigate(PATH_LOGIN);
                     });
                  }}
               />
            </BottomNavigation>
         </Box>
      </React.Fragment>
   );
}
